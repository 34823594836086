import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';

import { AttendanceLegend, EmptyComponent, ErrorPage, StrandPanelHeading } from 'components/shared';
import { Alert, Loading } from 'components';
import { EventLabels } from 'helpers/dictionaries';
import { eventExplorePropTypes } from 'helpers/proptypes';
import { AttendanceLog } from 'api';
import { requestEventsDetail } from 'store/actions/events/detail';
import { FacilitatorLabel } from 'components/events/components/EventRegistrationPanel';

import AttendanceTable from './AttendanceTable';

const { EVENT_TYPES, STRAND_TYPES } = EventLabels;

export class AttendanceRecordsPage extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired,
    requestEventsDetail: PropTypes.func.isRequired,
    eventLoading: PropTypes.bool.isRequired,
    eventError: PropTypes.bool.isRequired,
    event: eventExplorePropTypes,
  };

  state = {
    records: [],
    recordsLoading: true,
    recordsErrored: false,
  };

  componentDidMount() {
    const { eventId, userSlug } = this.props.match.params;
    this.props.requestEventsDetail(eventId);
    AttendanceLog.records(userSlug, eventId)
      .then((res) => this.setState({ records: res, recordsLoading: false }))
      .catch((err) => this.setState({ recordsError: err }));
  }

  render() {
    const { records, recordsLoading, recordsError } = this.state;
    const { eventLoading, eventError, event } = this.props;
    if (eventError) {
      return (
        <Alert type="danger" icon="fa-exclamation-triangle">
          Something went wrong. Please try again.
        </Alert>
      );
    }

    if (recordsError) {
      return <ErrorPage error={recordsError} />;
    }

    if (eventLoading || recordsLoading) {
      return <Loading />;
    }

    const { eventId, userSlug } = this.props.match.params;
    const Action = records.length > 0 && (
      <a
        href={window.URLS['pd:person_attendance_log_csv'](eventId, userSlug)}
        className="btn btn-default pull-right"
      >
        <i className="fa fa-download icon-space-r" />
        Download
      </a>
    );
    return (
      <div className="panel panel-default">
        <StrandPanelHeading
          title={STRAND_TYPES[event.strand_type]}
          strand={event.strand_type}
          Action={Action}
        />
        <div className="panel-body">
          <strong className="panel-type">
            {EVENT_TYPES[event.type]} ({event.academic_year} {event.academic_season})
          </strong>
          <h2 className="panel-head text-size-lg">{event.title}</h2>
          <FacilitatorLabel
            facilitators={event.facilitators}
            facilitator_names={event.facilitator_names}
          />
          {records.length === 0 ? (
            <EmptyComponent
              icon="fa-calendar-o"
              title="No attendance has been logged."
              subtitle="Check with your facilitator(s) if you have questions or concerns."
            />
          ) : (
            <AttendanceTable records={records} />
          )}
          {records.length > 0 && (
            <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
              <AttendanceLegend />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    eventLoading: state.eventsDetail.isLoading,
    eventError: state.eventsDetail.hasErrored,
    event: state.eventsDetail.event,
  };
};

export default connect(
  mapStateToProps,
  { requestEventsDetail }
)(AttendanceRecordsPage);
