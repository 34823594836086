import React from 'react';
import PropTypes from 'prop-types';

import { failedAssertion } from 'helpers/reporting';
import { recordPropTypes } from 'helpers/proptypes';
import { AttendanceRecordDictionary } from 'helpers/dictionaries';

import AttendanceRecordTableRow from './AttendanceRecordTableRow';

const { ATTENDANCE_STATUS } = AttendanceRecordDictionary;

const AttendanceTable = ({ records }) => {
  if (!records || records.length === 0) {
    failedAssertion('AttendanceTable passed null or empty record set');
    return null;
  }
  const attendanceCounts = {};
  Object.values(ATTENDANCE_STATUS).forEach((status) => (attendanceCounts[status] = 0));
  records.forEach((record) => (attendanceCounts[record.status] += 1));
  const percent =
    ((attendanceCounts[ATTENDANCE_STATUS.PRESENT] + attendanceCounts[ATTENDANCE_STATUS.LATE]) /
      records.length) *
    100;
  const percentRowClassName = percent >= 90 ? 'success' : percent >= 80 ? 'warning' : 'danger';
  return (
    <div className="table-responsive">
      <table className="table table-striped table-condensed attendance-table">
        <thead className="nowrap">
          <tr>
            <th className="center">Total</th>
            {records.map((record) => (
              <th key={record.id} className="center">
                {record.attendancelog.date}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={`center ${percentRowClassName}`}>{percent.toFixed(1)}%</th>
            {records.map((record) => (
              <AttendanceRecordTableRow key={record.id} status={record.status} />
            ))}
          </tr>
          <tr>
            <th />
            {records.map((record, idx) => (
              <th key={idx} />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

AttendanceTable.propTypes = {
  records: PropTypes.arrayOf(recordPropTypes.isRequired).isRequired,
};

export default AttendanceTable;
