import React from 'react';
import PropTypes from 'prop-types';

import { AttendanceRecordDictionary } from 'helpers/dictionaries';

const { ATTENDANCE_STATUS } = AttendanceRecordDictionary;

const AttendanceRecordTableRow = ({ status }) => {
  switch (status) {
    case ATTENDANCE_STATUS.PRESENT:
      return (
        <td className="center success">
          <span className="fa fa-check-circle" />
        </td>
      );
    case ATTENDANCE_STATUS.LATE:
      return (
        <td className="center warning">
          <span className="fa fa-clock-o" />
        </td>
      );
    case ATTENDANCE_STATUS.ABSENT:
      return (
        <td className="center danger">
          <span className="fa fa-minus-circle" />
        </td>
      );
    default:
      return (
        <td className="center">
          <span className="fa fa-question-circle" />
        </td>
      );
  }
};

AttendanceRecordTableRow.propTypes = {
  status: PropTypes.oneOf(Object.values(ATTENDANCE_STATUS)).isRequired,
};

export default AttendanceRecordTableRow;
