import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { StrandPanelHeading } from 'components/shared';
import { ceuTitle } from '../helpers';
import { eventExplorePropTypes } from 'helpers/proptypes';
import { RegistrationStatusLabel } from 'components';

export const FacilitatorLabel = (props) => {
  return (
    <div className="info-description">
      {props.facilitators.length > 1 ? (
        <strong className="info-title">Facilitators</strong>
      ) : (
        <strong className="info-title">Facilitator</strong>
      )}
      {!props.facilitatorNames && props.facilitators.length === 0 ? (
        <p className="info-description">None Assigned</p>
      ) : (
        <p className="info-description">{props.facilitatorNames}</p>
      )}
    </div>
  );
};

FacilitatorLabel.propTypes = {
  facilitators: PropTypes.arrayOf(PropTypes.number).isRequired,
  facilitatorNames: PropTypes.string,
};

export const GuestSpeakerLabel = (props) => {
  if (!props.guestSpeaker) {
    return null;
  }
  return (
    <div className="info-description">
      <strong className="info-title">Guest Speaker</strong>
      <p className="info-description">{props.guestSpeaker}</p>
    </div>
  );
};

GuestSpeakerLabel.propTypes = {
  guestSpeaker: PropTypes.string,
};

const RegistrationTimesLabel = (props) => {
  let now = moment();
  const start = moment(props.start);
  const end = moment(props.end);
  const closesSoon = props.open && now.clone().add(5, 'days') > end;
  const closed = now > end;
  const upcoming = now < start;

  let description = null;
  if (props.open) {
    description = (
      <p className="info-description">
        Closes <strong>{end.format('dddd, MMM Do, LT')}</strong>
      </p>
    );
  }
  if (upcoming) {
    description = (
      <p className="info-description">
        Opens <strong>{start.format('dddd, MMM Do, LT')}</strong>
      </p>
    );
  }
  if (closed) {
    description = (
      <p className="info-description">
        Closed on <strong>{end.format('dddd, MMM Do, LT')}</strong>
      </p>
    );
  }
  return (
    <React.Fragment>
      <strong className="info-title">
        <span className="icon-space-r">{props.title}</span>
        {props.open && <span className="label label-success">Open</span>}
        {upcoming && <span className="label label-info">Upcoming</span>}
        {closesSoon && <span className="label label-warning">Closes Soon</span>}
        {closed && <span className="label label-danger">Closed</span>}
      </strong>
      {description}
    </React.Fragment>
  );
};

RegistrationTimesLabel.propTypes = {
  title: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

const EventRegistrationPanel = (props) => (
  <div className="panel panel-default">
    <StrandPanelHeading title={'Registration Information'} strand={props.event.strand_type} />
    <div className="panel-body">
      <div className="col-sm-6">
        <RegistrationTimesLabel
          title="Registration"
          start={props.event.registration_start}
          end={props.event.registration_end}
          open={props.registrationOpen}
        />
        <RegistrationTimesLabel
          title="Add Drop Period"
          start={props.event.add_drop_start}
          end={props.event.add_drop_end}
          open={props.addDropOpen}
        />
        <strong className="info-title">Event Starts</strong>
        <p className="info-description">{props.event.first}</p>
        <strong className="info-title">Event Ends</strong>
        <p className="info-description">{props.event.last}</p>
        <strong className="info-title">Capacity</strong>
        <p className="info-description">{props.event.capacity} people</p>
      </div>
      <div className="col-sm-6">
        <strong className="info-title" style={{ marginBottom: '22px' }}>
          Registration Status <RegistrationStatusLabel status={props.event.registration_status} />
        </strong>
        <strong className="info-title">CEUs</strong>
        <p className="info-description">{ceuTitle(props.event.ceu_val, props.event.ceu_type)}</p>
        <strong className="info-title">Instruction Level</strong>
        <p className="info-description">{props.event.instruction_level}</p>
        <FacilitatorLabel
          facilitators={props.event.facilitators}
          facilitatorNames={props.event.facilitator_names}
        />
        <GuestSpeakerLabel guestSpeaker={props.event.guest_speaker} />
        <strong className="info-title">Class Type</strong>
        <p className="info-description">This is an {props.event.learning_type} event.</p>
      </div>
    </div>
  </div>
);

EventRegistrationPanel.propTypes = {
  event: eventExplorePropTypes.isRequired,
  registrationOpen: PropTypes.bool.isRequired,
  addDropOpen: PropTypes.bool.isRequired,
};

export default EventRegistrationPanel;
